import { inject } from 'aurelia-framework';
import { ProductService } from 'services/product-service';
import { SessionService } from 'services/session-service';
import { Helper } from 'resources/extensions/helper';
import { OrderService } from 'services/order-service';
import { NavigationInstruction, Next } from 'aurelia-router';

const STATIC_ROUTES_WITH_CHILD_ROUTES = {
    'sell': 4,
    'sellv2': 7,
    'customer-portal': 6,
    'blog': 4,
    'blog-category': [4, 2],
    'author': [4, 2],
    'sitemap': 4
};

const STATIC_ROUTES_WITH_CHILD_ROUTES_DEFAULT = [
    'currency',
    'items-game',
    'accounts-game',
    'services',
    'skins-game',
    'swap',
    'unique-names',
    'sign-in',
    'blog-post'
];

const CUSTOMER_PORTAL_CHILD_ROUTES = {
    '': 3,
    'order-details': 5,
    'sold': 6,
    'purchased': 6,
    'swap': 6,
    'support': 6,
    'security': 5,
    'subscription': 5
};

const CUSTOMER_PORTAL_CHILD_ROUTES_DEFAULT = [
    'profile',
    'security',
    'verification',
    'balance',
    'support',
    'subscription',
    'purchased',
    'sold',
    'swap',
    'support',
    'referrals',
    'orders'
];

const CHAT_ROUTES = ['chat', 'products'];

const CUSTOMER_PORTAL_MULTIPLE = {
    'sold': CHAT_ROUTES,
    'purchased': CHAT_ROUTES,
    'swap': CHAT_ROUTES,
    'support': ['chat'],
    'security': ['withdraw', '2fa', 'reset-password'],
    'subscription': ['cashback', 'payment-details', 'manage-membership', 'cancel-membership']
};

const SITE_ROUTES = ['services', 'cart', 'sell', 'services', 'unique-names', 'contact', 'swap'];
const PROFILE_ROUTES = [
    'profile',
    'subscription',
    'referrals',
    'verification',
    'purchased',
    'sold',
    'balance',
    'security',
    'support',
    'services',
    'cart',
    'order-details',
    'contact',
    'unique-names',
    'sell',
    'swap'
];

const STOCK_PRODUCTS_ROUTES = ['accounts'];
const PRODUCT_ROUTES = ['currency', 'sell', 'services', 'swap'];

const ORDER_CHAT_ROUTES = ['chat', 'products', 'order-details'];
const ORDER_CHAT_MAP = new Map([
    ['chat', 'orderchat'],
    ['products', 'orderproducts'],
    ['order-details', 'orderdetails']
]);

@inject(SessionService, ProductService, OrderService, Helper)
export class PreActivateStep {
    /**
     * @param {SessionService} sessionService
     * @param {ProductService} productService
     * @param {OrderService} orderService
     * @param {Helper} helper
     */
    constructor(sessionService, productService, orderService, helper) {
        this.sessionService = sessionService;
        this.productService = productService;
        this.orderService = orderService;
        this.helper = helper;

        this.staticRoutesWithChildRoutes = STATIC_ROUTES_WITH_CHILD_ROUTES;
        this.staticRoutesWithChildRoutesDefault = STATIC_ROUTES_WITH_CHILD_ROUTES_DEFAULT;
        this.customerPortalChildRoutes = CUSTOMER_PORTAL_CHILD_ROUTES;
        this.customerPortalChildRoutesDefault = CUSTOMER_PORTAL_CHILD_ROUTES_DEFAULT;
        this.chatRoutes = CHAT_ROUTES;
        this.customerPortalMultiple = CUSTOMER_PORTAL_MULTIPLE;
        this.siteRoutes = SITE_ROUTES;
        this.profileRoutes = PROFILE_ROUTES;
        this.stockProductsRoutes = STOCK_PRODUCTS_ROUTES;
        this.productRoutes = PRODUCT_ROUTES;
        this.orderChatRoutes = ORDER_CHAT_ROUTES;
        this.orderChatMap = ORDER_CHAT_MAP;

        ['staticRoutesWith', 'customerPortal'].forEach(x => this.mapRanges(x));
    }

    mapRanges(name) {
        const multiple = this[`${name}Multiple`];
        if (multiple) {
            const multipleRoutes = [];
            Object.keys(multiple).forEach(root => {
                multiple[root].forEach(child => {
                    multipleRoutes.push(`${root}/${child}`);
                    this[`${name}ChildRoutes`][`${root}-${child}`] = this[`${name}ChildRoutes`][root];
                });
            });
            this[`${name}MultipleRoutes`] = multipleRoutes;
        }

        const routes = this[`${name}ChildRoutes`];
        const def = this[`${name}ChildRoutesDefault`];

        def?.forEach(x => routes[x] = 3);

        Object.keys(routes).forEach(x => {
            let val = routes[x];
            if (typeof val === 'number') val = this.helper.range(val, true);
            else val = this.helper.range(val[0], true, val[1]);
            routes[x] = val;
        });
    }

    /**
     * @param {NavigationInstruction} navigationInstruction
     * @param {Next} next
     * @returns {Promise<void>}
     * @external Promise
     */
    run(navigationInstruction, next) {
        this.childRoute = navigationInstruction.params.childRoute;
        this.routeName = navigationInstruction.config.name;

        const baseRoute = this.staticRoutesWithChildRoutes[this.routeName]
            ? this.routeName
            : this.routeName.replace(/-\w+$/, '');

        // Profle
        const profilePath = this.getRouteAction(this.profileRoutes, this.siteRoutes, this.sessionService.destroyProfilePath);
        if (profilePath) this.sessionService.saveProfilePath(profilePath);

        // Products
        const stockProductsRoute = this.getRouteAction(this.stockProductsRoutes, this.stockProductsRoutes, this.productService.removeStockProductsPath);
        if (stockProductsRoute) this.productService.saveStockProductsPath(stockProductsRoute);

        const productRoute = this.getRouteAction(this.productRoutes, this.productRoutes, this.productService.removeProductPath);
        if (productRoute) this.productService.saveProductPath(productRoute);

        // Orders
        const orderPath = this.orderChatRoutes.find(x => new RegExp(x).test(this.childRoute));
        if (orderPath) this.orderService.saveOrderPath(this.orderChatMap.get(orderPath));
        else this.orderService.removeOrderPath();

        if (navigationInstruction.params.childRoute) {
            const foundRoute = navigationInstruction.router.routes.find(x => x.name === baseRoute);
            const foundRouteCountSlashes = foundRoute?.route?.split('/');
            const currentUrlSlashes = navigationInstruction.fragment.slice(1).split('/');

            if (this.helper.includesSome(this.childRoute, this.customerPortalMultipleRoutes)) {
                this.childRoute = this.childRoute.split('/').slice(0, 2).join('-');
            } else if (this.childRoute.includes('order-details')) {
                this.childRoute = this.childRoute.split('/')[0];
            }

            if (foundRoute?.name === 'customer-portal' && !this.customerPortalChildRoutes[this.childRoute]?.includes(currentUrlSlashes?.length)) {
                navigationInstruction.router.navigateToRoute('404');
            } else if ((currentUrlSlashes?.length !== foundRouteCountSlashes?.length && !this.staticRoutesWithChildRoutes[baseRoute])
                || (currentUrlSlashes?.length !== foundRouteCountSlashes?.length && this.staticRoutesWithChildRoutes[baseRoute] && !this.staticRoutesWithChildRoutes[baseRoute].includes(currentUrlSlashes?.length))) {
                navigationInstruction.router.navigateToRoute('404');
            }
        }
        return next();
    }

    /**
     * Gets the path from the main or child route according to the current route
     * @param {string[]} childRoutes - List of child routes
     * @param {string[]} siteRoutes - List of site routes
     * @returns {string} - The matched route
     */
    getRouteFromProfile(childRoutes, siteRoutes) {
        const mainRoute = this.getRouteFromSite(siteRoutes) ?? this.childRoute;
        const mainRouteBase = mainRoute?.split('/')[0];
        return childRoutes.find(route => route === mainRouteBase);
    }

    /**
     * Gets the path according to the current route name
     * @param {string[]} routes - List of routes
     * @returns {string} - The matched route
     */
    getRouteFromSite(routes) {
        const baseRoute = this.staticRoutesWithChildRoutes[this.routeName]
            ? this.routeName
            : this.routeName.replace(/-\w+$/, '');
        return (routes).find(r => r === baseRoute);
    }

    /**
     * Gets or removes the path according to the current route
     * @param {string[]} childRoutes - List of child routes
     * @param {string[]} siteRoutes - List of site routes
     * @param {Function} destroyFunction - Function to call if no route is found
     * @returns {string | null} - The matched route or null
     */
    getRouteAction(childRoutes, siteRoutes, destroyFunction) {
        const route = this.getRouteFromProfile(childRoutes, siteRoutes);
        return route ? route : (destroyFunction(), null);
    }
}
