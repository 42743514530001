import { OrderChatStatus, OrderChatMessageUserType } from '../enums';
import { User } from './core-types';
import { PurchasedOrder } from './order-types';
import { Product } from './product-types';

/**
 * @typedef {object} OrderChat
 * @property {User | null} assignedUser - The user assigned to the chat
 * @property {number | null} assignedUserId - The ID of the assigned user
 * @property {Date | null} createdDate - The date the chat was created
 * @property {number} id - The unique identifier of the chat
 * @property {boolean} isDeleted - Indicates if the chat is deleted
 * @property {Message[]} messages - The messages in the chat
 * @property {PurchasedOrder} order - The order associated with the chat
 * @property {number} orderId - The ID of the associated order
 * @property {OrderChatStatus} status - The status of the chat
 * @property {LastMessage} lastMessage - The last message in the chat
 * @property {Date | null} updatedDate - The date the chat was last updated
 * @property {string | null} systemMessage - The system message associated with the chat
 */
export const OrderChat = null;

/**
 * @typedef {object} Message
 * @property {Attachment[]} attachments - The attachments in the message
 * @property {Date | null} createdDate - The date the message was created
 * @property {number} id - The unique identifier of the message
 * @property {boolean} isDeleted - Indicates if the message is deleted
 * @property {string} message - The content of the message
 * @property {number} orderChatId - The ID of the associated order chat
 * @property {Date | null} updatedDate - The date the message was last updated
 * @property {User} user - The user who sent the message
 * @property {number} userId - The ID of the user who sent the message
 * @property {OrderChatMessageUserType} userType - The type of user who sent the message
 */
export const Message = null;

/**
 * @typedef {object} Attachment
 * @property {string} fileHash - The hash of the file
 * @property {string} fileName - The name of the file
 * @property {string} mimeType - The MIME type of the file
 */
export const Attachment = null;

/**
 * @typedef {object} LastMessage
 * @property {string} message - The content of the last message
 * @property {Date | null} createdDate - The date the last message was created
 */
export const LastMessage = null;

/**
 * @typedef {object} RecentOrder
 * @property {Product[]} products - The products in the recent order
 */
export const RecentOrder = null;


export {};
