import { SteamGame } from '../enums';

/**
 * @typedef {object} GameForNav - Represents a game for navigation purposes.
 * @property {number} id - The unique identifier of the game.
 * @property {string} name - The name of the game.
 * @property {string} shortName - The short name of the game.
 * @property {boolean} isActive - Indicates if the game is active.
 * @property {string} gameNavigationIcon - The icon used for game navigation.
 * @property {string} schemaImagePath - The path to the schema image.
 * @property {string} productCategoryName - The name of the product category.
 * @property {string} dropdownText - The text displayed in the dropdown.
 * @property {string} sellIcon - The icon used for selling.
 * @property {string} sellAltText - The alternative text for the sell icon.
 * @property {string} sellAccountRedirectLink - The redirect link for selling accounts.
 * @property {string} title - The title of the game.
 * @property {string} metaDescription - The meta description of the game.
 * @property {number|undefined|null} position - The position of the game in a list.
 * @property {number} parentGameId - The identifier of the parent game.
 * @property {string[]} slug - The slug(s) associated with the game.
 * @property {string} slugName - The name of the slug.
 * @property {string} currencyProductSymbol - The symbol of the currency product.
 * @property {boolean} isTrending - Indicates if the game is trending.
 * @property {number} trendingPosition - The position of the game in trending list.
 * @property {number | undefined | null} currencyPercentage - The percentage of the currency.
 * @property {boolean} dynamicService - Indicates if the game has dynamic services.
 * @property {string} headerTitle - The title displayed in the header.
 * @property {GameForNav[] | null} items - The list of related games.
 * @property {string} category - The category of the game.
 * @property {SteamGame} steamAppId - The Steam application ID of the game.
 */
export const GameForNav = null;

/**
 * @typedef {object} GameSelectorOption - Represents an option in the game selector.
 * @property {number} id - The unique identifier of the game option.
 * @property {string} name - The name of the game option.
 * @property {string} shortName - The short name of the game option.
 * @property {string | null} icon - The icon associated with the game option.
 * @property {boolean} isTrending - Indicates if the game option is trending.
 * @property {SteamGame} steamAppId - The Steam application ID of the game option.
 * @property {string | null} currencyProductSymbol - The symbol of the currency product.
 */
export const GameSelectorOption = null;

export {};
