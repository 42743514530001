import { SteamGame } from '../enums';
import { Product } from './product-types';

/**
 * @typedef {object} SteamInventoryItem
 * @property {string} productId - The unique identifier for the product
 * @property {string} name - The name of the item
 * @property {string} type - The type of item, for CSGO this would be the type of weapon this skin belongs to
 * @property {string} category - The category of the item, such as weapon category
 * @property {string} collection - The collection to which the item belongs
 * @property {number | null | undefined} paintSeed - The paint seed of the item, used for randomizing appearance
 * @property {number | null} float - The float value of the item, indicating wear level
 * @property {string} instanceId - The instance identifier for the item
 * @property {string} assetId - The asset identifier for the item
 * @property {string} rarity - The rarity level of the item
 * @property {string} quality - The quality of the item
 * @property {string} iconUrl - The URL for the item's icon
 * @property {string} largeIconUrl - The URL for the item's large icon
 * @property {string} nameColor - The color code for the item's name
 * @property {string | null} exterior - The exterior condition of the item
 * @property {string} inspectUrl - The URL to inspect the item in-game
 * @property {Sticker[]} stickers - The stickers applied to the item
 * @property {string} backgroundColor - The background color code for the item
 * @property {number} price - The price of the item
 * @property {string} description - The description of the item
 * @property {SteamGame} appId - The application ID for the game the item belongs to
 * @property {string} localizedValueLabel - Helper value defined during UI runtime to display localized price values (doesn't come from the API)
 * @property {number} localizedValue - Helper value defined during UI runtime to display localized price values (doesn't come from the API)
 */
export const SteamInventoryItem = null;

/**
 * @typedef {object} Sticker
 * @property {string} name - The name of the sticker
 * @property {string} url - The URL of the sticker
 */
export const Sticker = null;

/**
 * @typedef {object} SteamInventory
 * @property {SteamInventoryItem[]} items - The list of items in the inventory
 * @property {number} fee - The fee associated with the inventory
 * @property {string | null} updated - The last updated timestamp of the inventory
 */
export const SteamInventory = null;

/**
 * Checks if the given item is a Steam inventory item.
 * @param {Product | SteamInventoryItem} item - The item to check.
 * @returns {boolean} True if the item is a Steam inventory item, false otherwise.
 */
export function isSteamInventoryItem(item) {
    return 'assetId' in item;
}

/**
 * Checks if the given item is a CG (ChicksGold) product.
 * @param {Product | SteamInventoryItem} item - The item to check.
 * @returns {boolean} - Returns true if the item is a CG product, otherwise false.
 */
export function isCgProduct(item) {
    return !isSteamInventoryItem(item);
}

export {};
