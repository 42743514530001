import { SteamGame, SellCustomType, OrderTransactionType } from '../enums';
import { Product, ProductCategory } from './product-types';
import { OrderChat } from './order-chat-types';

/**
 * @typedef {object} CustomOrderProduct
 * @property {number} productId - The ID of the product.
 * @property {number} quantity - The quantity of the product.
 * @property {number} orderQuantity - The quantity ordered.
 * @property {number | undefined} deliveryMethodId - The ID of the delivery method.
 * @property {string} character - The character associated with the order.
 * @property {number | undefined} gameModeId - The ID of the game mode.
 * @property {boolean | undefined} purchasedInsurance - Indicates if insurance was purchased.
 * @property {number | undefined} insuranceId - The ID of the insurance.
 * @property {number | undefined} productCategoryId - The ID of the product category.
 * @property {ProductCategory | undefined} productCategory - The category of the product.
 * @property {string | undefined} serviceFullName - The full name of the service.
 * @property {number | undefined} price - The price of the product.
 * @property {number | undefined} orderId - The ID of the order.
 * @property {boolean | undefined} isSell - Indicates if the product is for sale.
 * @property {SellCustomType | undefined} customType - The custom type of the product.
 * @property {object | undefined} additionalFields - Additional fields for the product.
 * @property {string[] | undefined} additionalImages - Additional images of the product.
 * @property {string | undefined} imagePath - The image path of the product.
 * @property {string | undefined} dynamicList - The dynamic list associated with the product.
 * @property {Product} product - The product details.
 * @property {boolean} isSteamProduct - Indicates if the product is a Steam product.
 * @property {SteamGame | undefined} steamAppId - The Steam app ID of the product.
 */
export const CustomOrderProduct = null;

/**
 * @typedef {object} CustomOrderRequest
 * @property {string} websiteShortCode - The short code of the website.
 * @property {CustomOrderProduct[]} products - The list of custom order products.
 */
export const CustomOrderRequest = null;

/**
 * @typedef {object} CustomOrder
 * @property {number} id - The ID of the custom order.
 * @property {CustomOrderProduct[]} products - The list of custom order products.
 * @property {string} status - The status of the custom order.
 * @property {boolean} fulfilled - Indicates if the custom order is fulfilled.
 * @property {number} convertedCurrencyTotal - The total amount in converted currency.
 * @property {number | null} partialRefundedAmount - The partially refunded amount.
 * @property {number | null} currencyRateUsed - The currency rate used for conversion.
 * @property {string} currencyUsed - The currency used for the custom order.
 * @property {number} totalPrice - The total price of the custom order.
 */
export const CustomOrder = null;

/**
 * @typedef {object} PurchasedOrder
 * @property {number} id - The ID of the purchased order.
 * @property {number} userId - The ID of the user who made the purchase.
 * @property {number} convertedCurrencyTotal - The total amount in converted currency.
 * @property {number | null} totalPrice - The total price of the purchased order.
 * @property {string} status - The status of the purchased order.
 * @property {string} fulfilled - Indicates if the purchased order is fulfilled.
 * @property {Date | null} createdDate - The date when the order was created.
 * @property {number | null} balanceAmount - The balance amount.
 * @property {number | null} remainingBalance - The remaining balance amount.
 * @property {number | null} partialRefundedAmount - The partially refunded amount.
 * @property {number | null} currencyRateUsed - The currency rate used for conversion.
 * @property {string} currencyUsed - The currency used for the purchased order.
 * @property {number | null} subscriptionCashback - The subscription cashback amount.
 * @property {OrderProduct[]} products - The list of products in the order.
 * @property {OrderProduct[]} services - The list of services in the order.
 * @property {OrderChat} orderChat - The chat associated with the order.
 * @property {OrderType | null} orderType - The type of the order.
 * @property {number | null} subtotal - The subtotal amount of the order.
 * @property {Charge[]} charges - The list of charges associated with the order.
 * @property {number | null} paymentMethodId - The ID of the payment method used.
 * @property {OrderTransactionType} transactionType - The transaction type of the order.
 * @property {OrderTransactionType} previousTransactionType - The previous transaction type of the order.
 */
export const PurchasedOrder = null;

/**
 * @typedef {object} OrderType
 * @property {number} id - The ID of the order type.
 * @property {string} name - The name of the order type.
 * @property {string} description - The description of the order type.
 */
export const OrderType = null;

/**
 * @typedef {object} Charge
 * @property {string} message - The message associated with the charge.
 * @property {string} additionalInformation - Additional information about the charge.
 */
export const Charge = null;

/**
 * @typedef {object} OrderProduct
 * @property {number | null} auxId - The auxiliary ID of the order product.
 * @property {string | null} character - The character associated with the order product.
 * @property {number} convertedPrice - The converted price of the order product.
 * @property {number} convertedTotalPrice - The converted total price of the order product.
 * @property {string | null} dynamicList - The dynamic list associated with the order product.
 * @property {number} fulfilledAmount - The fulfilled amount of the order product.
 * @property {number} id - The ID of the order product.
 * @property {string | null} imagePath - The image path of the order product.
 * @property {number | null} insuranceFee - The insurance fee of the order product.
 * @property {SellCustomType | null} customType - The custom type of the order product.
 * @property {boolean} isCustomOrder - Indicates if the order product is a custom order.
 * @property {boolean} isFreeTrial - Indicates if the order product is a free trial.
 * @property {boolean} isSell - Indicates if the order product is for sale.
 * @property {number} orderId - The ID of the order.
 * @property {number} price - The price of the order product.
 * @property {Product} product - The product details.
 * @property {number} productId - The ID of the product.
 * @property {boolean} purchasedInsurance - Indicates if insurance was purchased for the order product.
 * @property {number} quantity - The quantity of the order product.
 * @property {string | null} serviceFullName - The full name of the service associated with the order product.
 * @property {number} totalPrice - The total price of the order product.
 */
export const OrderProduct = null;

/**
 * @typedef {object} PaymentMethod
 * @property {number} id - The ID of the payment method.
 * @property {string} name - The name of the payment method.
 * @property {string | null} imagePath - The image path of the payment method.
 * @property {string} reference - The reference of the payment method.
 * @property {string | null} paymentAddress - The payment address of the payment method.
 * @property {string | null} cryptoAbbreviation - The cryptocurrency abbreviation of the payment method.
 * @property {string | null} qrCodeValue - The QR code value of the payment method.
 * @property {string | null} paypalUrl - The PayPal URL of the payment method.
 * @property {boolean} usesCard - Indicates if the payment method uses a card.
 * @property {boolean} isStable - Indicates if the payment method is stable.
 * @property {string | null} cardInfo - The card information of the payment method.
 * @property {number} paymentMethodCategoryId - The category ID of the payment method.
 */
export const PaymentMethod = null;

/**
 * @typedef {object} PaymentDetails
 * @property {number} websiteId - The ID of the website.
 * @property {number} paymentMethodId - The ID of the payment method.
 * @property {number} fee - The fee associated with the payment.
 * @property {number | null} receivingFee - The receiving fee associated with the payment.
 * @property {number | null} proccessorPercentFee - The processor percent fee associated with the payment.
 * @property {number | null} proccessoBaseFee - The processor base fee associated with the payment.
 * @property {number | null} additionalInternalFee - The additional internal fee associated with the payment.
 * @property {number} defaultValue - The default value of the payment.
 * @property {number} smallOrderFee - The small order fee associated with the payment.
 * @property {number} smallOrderMinimumAmount - The minimum amount for small orders.
 * @property {string | null} customImagePath - The custom image path for the payment.
 * @property {boolean} live - Indicates if the payment is live.
 * @property {boolean} calculateTaxes - Indicates if taxes are calculated for the payment.
 * @property {number} minimum - The minimum amount for the payment.
 * @property {number} maximum - The maximum amount for the payment.
 * @property {string | null} withdrawStepNotes - The notes for the withdrawal step.
 * @property {string} paymentStepNotes - The notes for the payment step.
 * @property {string | null} orderCompletedStepNotes - The notes for the order completed step.
 * @property {number} position - The position of the payment method.
 * @property {boolean} payoutable - Indicates if the payment method is payoutable.
 * @property {boolean} additionalBalance - Indicates if there is an additional balance for the payment.
 * @property {boolean} is3dsRequired - Indicates if 3DS is required for the payment.
 * @property {PaymentMethod} paymentMethod - The payment method details.
 * @property {number | null} spreadFee - The spread fee associated with the payment.
 * @property {number | null} baseWithdrawFee - The base withdrawal fee associated with the payment.
 * @property {number | null} withdrawFee - The withdrawal fee associated with the payment.
 * @property {number | null} minimumWithdrawAmount - The minimum withdrawal amount.
 * @property {number | null} maximumWithdrawAmount - The maximum withdrawal amount.
 * @property {Array} supportedCurrencies - The supported currencies for the payment.
 * @property {string | null} restrictedCountries - The restricted countries for the payment.
 * @property {string | null} transactionAddressType - The transaction address type for the payment.
 */
export const PaymentDetails = null;

export {};

