import { ProductCategoryType, DeliveryPeriod, AccountAcquiredType } from '../enums';
import { GameForNav } from './game-types';

/**
 * @readonly
 * @enum {number}
 */
export const AdvancedSearchMode = {
    Default: 0,
    NoCount: 1,
    CountOnly: 2
};

/**
 * @typedef {object} AdvancedProductSearchRequest
 * @property {number} pageSize - The number of products per page.
 * @property {number} page - The current page number.
 * @property {string | null} [query] - The search query string.
 * @property {ProductCategoryType | null} [productCategory] - The category of the product.
 * @property {number | null} [gameId] - The ID of the game.
 * @property {number} startPrice - The starting price range.
 * @property {number | null} [endPrice] - The ending price range.
 * @property {AdvancedSearchFilter[] | null} [filters] - The list of advanced search filters.
 * @property {string} sort - The sorting criteria.
 * @property {string | null} [currency] - The currency type.
 * @property {number | null} [rate] - The exchange rate.
 * @property {boolean} [sell] - Indicates if the search is for selling products.
 * @property {AdvancedSearchMode} [mode] - Modifier for the advanced search operation
 */
export const AdvancedProductSearchRequest = null;

/**
 * @typedef {object} AdvancedSearchFilter
 * @property {number} fieldId - The ID of the field to filter by.
 * @property {string} value - The value to filter by.
 * @property {number | null | undefined} startValue - The starting value for range filters.
 * @property {number | null | undefined} endValue - The ending value for range filters.
 * @property {boolean} [isAdditionalFilter] - Indicates if this is an additional filter.
 */
export const AdvancedSearchFilter = null;

/**
 * @typedef {object} ActiveSearchFilter
 * @property {number} parentId - The ID of the parent filter.
 * @property {string} value - The value of the active filter.
 * @property {number | null | undefined} startValue - The starting value for range filters.
 * @property {number | null | undefined} endValue - The ending value for range filters.
 * @property {boolean} isAdditionalFilter - Indicates if this is an additional filter.
 */
export const ActiveSearchFilter = null;

/**
 * @typedef {object} AdvancedProductSearchResponse
 * @template T
 * @property {number} pageSize - The number of products per page.
 * @property {number} pages - The total number of pages.
 * @property {number} page - The current page number.
 * @property {Array<T>} products - The list of products for the current page.
 * @property {number} totalCount - The total count of products calculated for this search
 */
export const AdvancedProductSearchResponse = null;

/**
 * @typedef {object} Product
 * @property {number} id - The unique identifier of the product.
 * @property {string} name - The name of the product.
 * @property {number} price - The price of the product.
 * @property {number | undefined | null} salePrice - The sale price of the product, if any.
 * @property {number} quantity - The quantity of the product available.
 * @property {number} tempQuantity - The temporary quantity of the product.
 * @property {string} imagePath - The path to the product's image.
 * @property {string} schemaImagePath - The path to the product's schema image.
 * @property {string} mimeType - The MIME type of the product's image.
 * @property {ProductImage[] | null} productImages - The list of images associated with the product.
 * @property {number | null} minimum - The minimum quantity of the product.
 * @property {number | null} maximum - The maximum quantity of the product.
 * @property {number} swapToFee - The fee for swapping to this product.
 * @property {string} link - The link to the product's page.
 * @property {string} alignment - The alignment of the product.
 * @property {string} difficulty - The difficulty level of the product.
 * @property {number | undefined | null} position - The position of the product in a list.
 * @property {number | undefined | null} defaultAmount - The default amount of the product.
 * @property {number} goldRequired - The amount of gold required for the product.
 * @property {AccountAcquiredType} accountAcquiredType - The type of account acquired for the product.
 * @property {string} wikiLink - The link to the product's wiki page.
 * @property {string | null} character - The character associated with the product.
 * @property {string} serviceFullName - The full name of the service associated with the product.
 * @property {number} selectedQuantity - The selected quantity of the product.
 * @property {number | undefined | null} userCartId - The ID of the user's cart.
 * @property {boolean} isService - Indicates if the product is a service.
 * @property {number | undefined | null} deliveryMethodId - The ID of the delivery method.
 * @property {DeliveryMethod} deliveryMethod - The delivery method of the product.
 * @property {number | undefined | null} productCategoryId - The ID of the product category.
 * @property {ProductCategory} productCategory - The category of the product.
 * @property {number | undefined | null} gameId - The ID of the game associated with the product.
 * @property {GameForNav} game - The game associated with the product.
 * @property {string} title - The title of the product.
 * @property {ProductField[]} productFields - The fields associated with the product.
 * @property {number | null} margin - The margin of the product.
 * @property {number | undefined} productId - Helper property for the swap flow.
 * @property {string | undefined} dynamicList - Helper property for the swap flow.
 * @property {string | undefined} helperValue - Helper property for the swap flow.
 * @property {number | null} auxId - The auxiliary ID of the product.
 * @property {string | null} steamId - The Steam ID associated with the product.
 * @property {number | null} deliveryTime - The delivery time of the product.
 * @property {number | null} averageDeliveryTime - The average delivery time of the product.
 * @property {DeliveryPeriod | null} deliveryPeriod - The delivery period of the product.
 * @property {string | null} deliveryPeriodText - The text description of the delivery period.
 */
export const Product = null;

/**
 * @typedef {object} ProductImage
 * @property {number} id - The unique identifier of the product image.
 * @property {string} imagePath - The path to the product image.
 * @property {number} productId - The ID of the product associated with the image.
 * @property {string} mimeType - The MIME type of the product image.
 * @property {boolean} isSticker - Indicates if the image is a sticker.
 * @property {ProductName} product - The product associated with the image.
 * @property {boolean} isDeleted - Indicates if the image is deleted.
 * @property {Date} createdDate - The date the image was created.
 * @property {Date} updatedDate - The date the image was last updated.
 */
export const ProductImage = null;

/**
 * @typedef {object} ProductName
 * @property {number} id - The unique identifier of the product name.
 * @property {string} name - The name of the product.
 */
export const ProductName = null;

/**
 * @typedef {object} DeliveryMethod
 * @property {string} name - The name of the delivery method.
 * @property {string} displayName - The display name of the delivery method.
 * @property {number} markupPercent - The markup percentage for the delivery method.
 * @property {number} gameId - The ID of the game associated with the delivery method.
 * @property {number} categoryId - The ID of the category associated with the delivery method.
 * @property {number | undefined | null} position - The position of the delivery method.
 * @property {boolean | undefined | null} default - Indicates if the delivery method is the default.
 */
export const DeliveryMethod = null;

/**
 * @typedef {object} ProductCategory
 * @property {number} id - The unique identifier of the product category.
 * @property {string} name - The name of the product category.
 */
export const ProductCategory = null;

/**
 * @typedef {object} ProductField
 * @property {number} id - The unique identifier of the product field.
 * @property {number} productId - The ID of the product associated with the field.
 * @property {number} fieldId - The ID of the field.
 * @property {ProductFieldValue | null} value - The value of the product field.
 * @property {string} actualValue - The actual value of the product field.
 * @property {Field | null} field - The field associated with the product field.
 * @property {boolean} isDeleted - Indicates if the product field is deleted.
 * @property {Date} createdDate - The date the product field was created.
 * @property {Date} updatedDate - The date the product field was last updated.
 */
export const ProductField = null;

/**
 * @typedef {object} ProductFieldValue
 * @property {string} display - The display value of the product field value.
 * @property {string} value - The value of the product field value.
 * @property {number} parentId - The ID of the parent field value.
 * @property {number | null} position - The position of the field value.
 * @property {string | null} label - The label of the field value.
 * @property {number | null} filteredByListOfValueId - The ID of the list of values filtered by this field value.
 * @property {number} id - The unique identifier of the field value.
 * @property {string} createdDate - The date the field value was created.
 * @property {string | null} updatedDate - The date the field value was last updated.
 * @property {boolean} isDeleted - Indicates if the field value is deleted.
 */
export const ProductFieldValue = null;

/**
 * @typedef {object} Field
 * @property {number} id - The unique identifier of the field.
 * @property {string} name - The name of the field.
 * @property {string} label - The label of the field.
 * @property {string} value - The value of the field.
 * @property {ListOfValueName} listOfValue - The list of values associated with the field.
 * @property {boolean | null | undefined} isDescription - Indicates if the field is a description.
 * @property {boolean | null | undefined} isFilter - Indicates if the field is a filter.
 * @property {number} parentId - The ID of the parent field.
 */
export const Field = null;

/**
 * @typedef {object} ListOfValueName
 * @property {number} id - The unique identifier of the list of value name.
 * @property {string} display - The display value of the list of value name.
 * @property {string} label - The label of the list of value name.
 */
export const ListOfValueName = null;

/**
 * @typedef {object} StockProduct
 * @property {number} id - The unique identifier of the stock product.
 * @property {string} name - The name of the stock product.
 * @property {string | null} description - The description of the stock product.
 * @property {number} quantity - The quantity of the stock product available.
 * @property {string} imagePath - The path to the stock product's image.
 * @property {ProductImage[]} productImages - The list of images associated with the stock product.
 * @property {string | null} mimeType - The MIME type of the stock product's image.
 * @property {number} price - The price of the stock product.
 * @property {number | null} salePrice - The sale price of the stock product, if any.
 * @property {number} maximum - The maximum quantity of the stock product.
 * @property {number} minimum - The minimum quantity of the stock product.
 * @property {number} gameId - The ID of the game associated with the stock product.
 * @property {boolean} isDeleted - Indicates if the stock product is deleted.
 * @property {number | null} margin - The margin of the stock product.
 * @property {ProductCategory} productCategory - The category of the stock product.
 * @property {number} productCategoryId - The ID of the product category.
 * @property {number | null} productSubCategoryId - The ID of the product sub-category.
 * @property {ProductField[]} productFields - The fields associated with the stock product.
 * @property {number | null} productFieldId - The ID of the product field.
 * @property {Region | null} region - The region associated with the stock product.
 * @property {number | null} regionId - The ID of the region.
 * @property {AccountAcquiredType} accountAcquiredType - The type of account acquired for the stock product.
 * @property {Date | null} createdDate - The date the stock product was created.
 * @property {Date | null} updatedDate - The date the stock product was last updated.
 */
export const StockProduct = null;

/**
 * @typedef {object} Region
 * @property {number} id - The unique identifier of the region.
 * @property {string} name - The name of the region.
 * @property {string} displayName - The display name of the region.
 * @property {boolean} isDeleted - Indicates if the region is deleted.
 * @property {Date | null} createdDate - The date the region was created.
 * @property {Date | null} updatedDate - The date the region was last updated.
 */
export const Region = null;

/**
 * @typedef {object} ActiveFilter
 * @property {number} parentId - The ID of the parent filter.
 * @property {string} value - The value of the active filter.
 * @property {string} display - The display value of the active filter.
 */
export const ActiveFilter = null;

/**
 * @typedef {object} ProductOffer
 * @property {string} name - The name of the product offer.
 * @property {number} price - The price of the product offer.
 * @property {number} quantity - The quantity of the product offer.
 * @property {number} productCategoryId - The ID of the product category.
 * @property {number} gameId - The ID of the game associated with the product offer.
 * @property {number} status - The status of the product offer.
 * @property {string} title - The title of the product offer.
 * @property {number} sellerId - The ID of the seller.
 * @property {Field[]} fields - The fields associated with the product offer.
 */
export const ProductOffer = null;

/**
 * @typedef {object} AdvancedProductSearchProductResponse
 * @property {number} id - The unique identifier of the product.
 * @property {number | null} [margin] - The margin value, which can be null.
 * @property {number} price - The product price.
 * @property {number | null} [salePrice] - The sale price, which can be null.
 * @property {number | null} [productCategoryId] - The product category ID, which can be null.
 * @property {ProductCategoryBasicResponse | null} [productCategory] - The product category details, which can be null.
 * @property {number | null} [gameId] - The game ID, which can be null.
 * @property {string} name - The name of the product.
 * @property {number} quantity - The quantity of the product.
 * @property {string} imagePath - The path to the product image.
 * @property {string} mimeType - The MIME type of the product image.
 * @property {ProductImageResponse[]} productImages - A list of product images.
 * @property {ProductField[]} productFields - A list of product fields.
 * @property {number} minimum - The minimum quantity of the product.
 * @property {number} maximum - The maximum quantity of the product.
 * @property {number | null} [defaultAmount] - The default amount, which can be null.
 * @property {string} description - The product description.
 * @property {number} deliveryTime - The delivery time in some unit (e.g., hours or days).
 * @property {DeliveryPeriod | null} [deliveryPeriod] - The delivery period, which can be null.
 * @property {string} deliveryPeriodText - The delivery period description.
 * @property {number} [selectedQuantity] - Frontend only property. It represents the quantity selected when the user pressed the **Add** button
 * @property {string} [serviceFullName] - Frontend only property. Used to identify dynamic products from the same category but different games
 * @property {number} [expectedPrice] - Frontend only property. Used to save the expected price of the product set by the customer
 * @property {{ name: string, value: string | number }[]} [dynamicList] - Frontend only property. Used to save a dynamic set of properties
 */
export const AdvancedProductSearchProductResponse = null;

/**
 * @typedef {object} ProductCategoryBasicResponse
 * @property {number} id - The unique identifier of the product category.
 * @property {string} name - The name of the product category.
 * @property {boolean} isAutoDelivery - Indicates if the product category is auto-delivery.
 */
export const ProductCategoryBasicResponse = null;

/**
 * @typedef {object} ProductImageResponse
 * @property {number} id - The unique identifier of the product image.
 * @property {string} imagePath - The path to the product image.
 * @property {string} mimeType - The MIME type of the product image.
 * @property {boolean} isSticker - Indicates if the image is a sticker.
 */
export const ProductImageResponse = null;

/**
 * @typedef {object} ProductCategoryFilterOption
 * @property {number} id - The unique identifier of the product category filter option.
 * @property {ProductCategoryType} name - The name of the product category type.
 * @property {string} icon - The icon representing the product category filter option.
 */
export const ProductCategoryFilterOption = null;

/**
 * @typedef {object} SwapCategorySelectedEvent
 * @property {number | null} id - The unique identifier of the swap category selected event.
 */
export const SwapCategorySelectedEvent = null;

export {};
