import { IPaymentMethodWebsiteResponse } from '@chicksgroup/types';
import { SubscriptionPaymentPlan } from '../enums';

/**
 * @typedef {object} Subscription
 * @property {number} subscriptionId - The unique identifier for the subscription.
 * @property {number} id - The unique identifier for the user.
 * @property {SubscriptionPaymentPlan} paymentPlan - The payment plan associated with the subscription.
 * @property {Date} renewalDate - The date when the subscription will be renewed.
 * @property {Date} subscribedAt - The date when the subscription was created.
 * @property {Date | null} unsubscribedAt - The date when the subscription was canceled, or null if still active.
 * @property {boolean} isSubscribed - Indicates whether the subscription is currently active.
 * @property {Reason[]} reasons - The reasons for subscribing or unsubscribing.
 * @property {string} unsubscribeOtherReason - Additional reason for unsubscribing if not covered by predefined reasons.
 * @property {number | null} mainPaymentMethodId - The ID of the main payment method, or null if not set.
 * @property {number | null} backupPaymentMethodId - The ID of the backup payment method, or null if not set.
 * @property {string} mainCardLastDigits - The last digits of the main payment method card.
 * @property {string} backupCardLastDigits - The last digits of the backup payment method card.
 * @property {IPaymentMethodWebsiteResponse} mainPaymentMethod - The main payment method details.
 * @property {IPaymentMethodWebsiteResponse} backupPaymentMethod - The backup payment method details.
 */
export const Subscription = null;

/**
 * @typedef {object} Reason
 * @property {string} unsubscribeReason - The reason provided for unsubscribing.
 */
export const Reason = null;

export {};

