import { inject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { Helper } from 'resources/extensions/helper';
import { staticProductCategories } from 'resources/constants';
import { ProductCategory } from 'resources/helpers/types';

@inject(ApiService, Helper)
export class ProductCategoryService {
    path = 'ProductCategory';
    navItems;
    productCategories;
    staticProductCategoriesForSell = (staticProductCategories()).map(x => x.toLowerCase());

    /**
     * @param {ApiService} api
     * @param {Helper} helper
     */
    constructor(api, helper) {
        this.api = api;
        this.helper = helper;
    }

    /**
     * @returns {Promise<ProductCategory[]>} A promise that resolves to an array of product categories.
     * @external Promise
     */
    async getAll() {
        if (this.productCategories) return this.productCategories;
        this.productCategories = await this.api.doGet(this.path);
        return this.productCategories;
    }

    async getAllForNav(websiteShortCode) {
        return this.helper.handlePendingRequest(this, async() => {
            if (!this.navItems) {
                this.navItems = await this.api.doGet(this.path + '/ForNav?websiteShortCode=' + websiteShortCode);
            }

            this.buildExtraSell();

            return this.navItems.filter(c => c.name !== 'sellextra' && c.name !== 'WOWTLKPW Power Leveling');
        });
    }

    async getNavCategory(category, websiteShortCode) {
        if (category) {
            if (!this.navItems) {
                this.navItems = await this.api.doGet(this.path + `/ForNav?websiteShortCode=${websiteShortCode}`);
            }
            this.buildExtraSell();
            return this.navItems.find(x => x.name?.toLowerCase() === category?.toLowerCase());
        } else {
            throw Error('Category given was null');
        }
    }

    async getIdByName(name) {
        return await this.api.doGet(this.path + '/GetIdByName/' + name);
    }

    buildExtraSell() {
        if (this.navItems.some(x => x.name === 'sellextra')) return;

        const auxNavItems = JSON.parse(JSON.stringify(this.navItems));

        const sellGames = this.staticProductCategoriesForSell.map(x => {
            let name = ['items', 'accounts'].includes(x) ? x.slice(0, -1) : x;
            name = this.helper.toCapitalize(name, true);
            return auxNavItems?.find(i => i.name === `Sell ${name}`)?.gameForNav
                ?.map(e => {
                    e.slug = e.slug.map(s => `${this.helper.toRoute(x)}/${s}`);
                    e.productCategoryName = x.toUpperCase();
                    return e;
                });
        }).filter(x => x);

        const sellList = {
            id: 250,
            name: 'sellextra',
            gameForNav: sellGames.flat()
        };

        this.navItems.push(sellList);
    }
}
