import { inject } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { ToastType } from 'resources/helpers/enums';

@inject(EventAggregator)
export class ToastService {
    constructor(eventAggregator) {
        this.eventAggregator = eventAggregator;
    }

    defaultTitles = {
        [ToastType.SUCCESS]: 'Success!'
    };

    async showToast(toastType, message, title, imgIcon, screenTime = 2500, url, urlName, clickHereActive = false, actionLabel) {
        title ??= this.defaultTitles[toastType] ?? toastType.toPascal();
        this.eventAggregator.publish('toast', { title, message, toastType, imgIcon, forceOut: false, screenTime: screenTime, url: url, urlName: urlName, clickHereActive: clickHereActive, actionLabel: actionLabel });
    }
}
