import { inject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { websiteShortCode } from 'environment';
import { CamelCaseValueConverter } from 'resources/value-converters/camel-case';
import { Helper } from 'resources/extensions/helper';
import { SiteSettingService } from './site-setting-service';
import moment from 'moment';
import { NotificationType, ReasonType } from 'services/models/interceptor/notificationType';
import { ErrorReason } from 'services/models/interceptor/errorReason';
import { ToastService } from './toast-service';
import { ToastType } from 'resources/helpers/enums';
import { IPasswordResetRequest } from '@chicksgroup/types';

@inject(ApiService, EventAggregator, CamelCaseValueConverter, Helper, SiteSettingService, ToastService)
export class CustomerService {
    path = 'User';
    idPath = this.path + '/';
    checkoutVaultProfile;
    blueSnapVaultProfile;
    paysafeVaultProfile;

    constructor(api, eventAggregator, camelCaseValueConverter, helper, siteSettingService, toastService) {
        this.api = api;
        this.eventAggregator = eventAggregator;
        this.camelCaseValueConverter = camelCaseValueConverter;
        this.helper = helper;
        this.siteSettingService = siteSettingService;
        this.toastService = toastService;
    }

    get = async(path) => await this.api.doGet(`${this.path}/${path}`);

    async register(data) {
        data.websiteShortCode = websiteShortCode();
        data.fingerprint = await this.siteSettingService.getDeviceInfoForUser();
        const registerResponse = await this.api.doPost(this.path, data);
        if (registerResponse) {
            if (registerResponse.alreadyRegistered) {
                this.toastService.showToast(ToastType.INFO, `Account was created on ${moment.utc(registerResponse.createdDate).local().format('MM/DD/YYYY')} on ${registerResponse.website?.name ?? 'Chicks Gold'}. Please sign in instead with the same credentials.`, 'Welcome back');
                return true;
            }
        }
        this.helper.handleGtagEvent('sign_up', null, null, null, null, data?.signInOption);
        this.helper.handleFacebookPixelEvent('CompleteRegistration');
        this.helper.handleRedditEvent('SignUp');
        return registerResponse;
    }

    async send2FAEmail(userEmail, userPassword) {
        return await this.api.doPost(`${this.path}/Send2FAEmail`, { userEmail, userPassword, websiteShortCode: websiteShortCode() });
    }

    async reset2FAByEmail(userEmail, token) {
        return await this.api.doGet(this.helper.toParams(this.path + '/Reset2FAByEmail', { userEmail, token, websiteShortCode: websiteShortCode() }));
    }

    async verify2FAEmail(userEmail, token) {
        return await this.api.doGet(this.helper.toParams(this.path + '/Verify2FAEmail', { userEmail, token, websiteShortCode: websiteShortCode() }));
    }

    async sendWithdrawEmail() {
        return await this.api.doGet(this.helper.toParams(this.path + '/SendWithdrawEmail', { websiteShortCode: websiteShortCode() }));
    }

    async resetWithdrawPasswordByEmail(userEmail, token) {
        return await this.api.doGet(this.helper.toParams(this.path + '/ResetWithdrawPasswordByEmail', { userEmail, token, websiteShortCode: websiteShortCode() }));
    }

    async verifyWithdrawEmail(userEmail, token) {
        return await this.api.doGet(this.helper.toParams(this.path + '/VerifyWithdrawEmail', { userEmail, token, websiteShortCode: websiteShortCode() }));
    }

    async updateInformation(data) {
        data.websiteShortCode = websiteShortCode();
        return await this.api.doPut(this.path + '/updateInformation', data);
    }

    async deleteProfile(password) {
        return await this.api.doDelete(this.path + '/deleteProfile', password);
    }

    async requestEmailConfirmation() {
        return await this.api.doGet(this.path + '/RequestEmailConfirmation?websiteShortCode=' + websiteShortCode());
    }

    async sendContactEmail(from, message, topic, name, attachments = null) {
        const data = {
            from: from,
            message: message,
            topic: topic,
            name: name,
            websiteShortCode: websiteShortCode(),
            attachments: attachments ? JSON.stringify({ files: attachments }) : null
        };
        return await this.api.doPost('Contact', data);
    }

    async checkIfUserDeleted() {
        return await this.api.doGet(this.path + '/checkIfUserDeleted');
    }

    async setPaymentMethod(paymentMethodId) {
        const data = { paymentMethodId: paymentMethodId };
        return await this.api.doPut(this.path + '/updateInformation', data);
    }

    async requestTwoFactorAuthentication() {
        return await this.api.doGet(this.path + '/requestTwoFactorAuthentication');
    }

    async confirmTwoFactorActivation(token) {
        return await this.api.doPost(this.path + '/confirmTwoFactorActivation', { token });
    }

    async checkIfTwoFactorEnabled() {
        return await this.api.doGet(this.path + '/checkIfTwoFactorEnabled');
    }

    async removeTwoFactorAuthentication(token) {
        return await this.api.doPost(this.path + '/removeTwoFactorAuthentication', { token });
    }

    /**
     * @param {IPasswordResetRequest} data
     * @returns {Promise<boolean>} A promise that resolves to a boolean indicating the success of the password reset request.
     * @external Promise
     */
    async requestPasswordReset(data) {
        data.websiteShortCode = websiteShortCode();
        return await this.api.doPost('requestResetPassword', data);
    }

    async updatePassword(currentPassword, newPassword, isWithdraw = false) {
        const response = await this.api.doPost(this.path + '/updatePassword', { currentPassword, newPassword, isWithdraw });
        if (response === null) throw new Error('Failed to update password');
        return response;
    }

    async resetPasswordWithToken(email, newPassword, token) {
        const data = { newPassword, email, accessToken: token };
        return await this.api.doPost('ResetPassword', data);
    }

    async verifyResetPasswordToken(token, email) {
        const data = { token, email };
        return await this.api.doGet('VerifyPasswordResetToken', data);
    }

    async registerPhone(countryCode, phone, countryFlag) {
        const data = { phoneNumber: phone, countryCode: countryCode, phoneCountryFlag: countryFlag };
        return await this.api.doPost(this.path + '/RegisterPhoneNumber', data);
    }

    async requestCall() {
        return await this.api.doGet(this.path + '/RequestCallVerification');
    }

    async verifyPhone(token, usingTwilio, verificationId, idToken) {
        const data = { token: token, usingTwilio: usingTwilio, verificationId: verificationId, idToken: idToken };
        return await this.api.doPost(this.path + '/VerifyPhoneNumber', data);
    }

    async getBlueSnapVaultedShopperInformation(forceRefresh = true) {
        if (!forceRefresh && this.blueSnapVaultProfile) return this.blueSnapVaultProfile;
        this.blueSnapVaultProfile = await this.api.doGet(this.path + '/VaultedShopperInformation');
        return this.blueSnapVaultProfile;
    }

    async confirmEmail(email, token) {
        const data = { email: email, token: token };
        return await this.api.doPost(this.path + '/ConfirmEmail', data);
    }

    async getCheckoutVaultProfile(forceRefresh = true) {
        if (!forceRefresh && this.checkoutVaultProfile) return this.checkoutVaultProfile;
        this.checkoutVaultProfile = await this.api.doGet(this.path + '/CheckoutVaultProfile');
        if (this.checkoutVaultProfile) this.checkoutVaultProfile = this.camelCaseValueConverter.toView(this.checkoutVaultProfile);
        return this.checkoutVaultProfile;
    }

    async getCheckoutVaultLastUsedCard() {
        let result = await this.api.doGet(this.path + '/CheckoutVaultLastUsedCard');
        if (result) {
            result = this.camelCaseValueConverter.toView(result);
        }
        return result;
    }

    async getPaysafeVaultProfile(forceRefresh = true) {
        if (!forceRefresh && this.paysafeVaultProfile) return this.paysafeVaultProfile;
        this.paysafeVaultProfile = await this.api.doGet(this.path + '/PaysafeVaultProfile');
        return this.paysafeVaultProfile;
    }

    async getPaysafeVaultLastUsedCard() {
        return await this.api.doGet(this.path + '/PaysafeVaultLastUsedCard?websiteShortCode=' + websiteShortCode());
    }

    async deletePaysafeCard(cardId) {
        return await this.api.doDelete(this.path + '/DeleteSavedCard?paysafeCardId=' + cardId);
    }

    async deleteBluesnapCard(cardLast4, cardType) {
        return await this.api.doDelete(this.path + '/DeleteSavedCard?blueSnapCardLast4=' + cardLast4 + '&blueSnapCardType=' + cardType);
    }

    async deleteCheckoutCard(instrumentId) {
        return await this.api.doDelete(this.path + '/DeleteSavedCard?checkoutInstrumentId=' + instrumentId);
    }

    async updateBluesnapCard(firstName, lastName, card, expiryDate) {
        const data = {
            firstName: firstName,
            lastName: lastName,
            cardLastFourDigits: card.creditCard.cardLastFourDigits,
            cardType: card.creditCard.cardType,
            expirationMonth: expiryDate.substring(0, 2),
            expirationYear: expiryDate.substring(expiryDate.length - 2),
            address: card.billingContactInfo.address1,
            city: card.billingContactInfo.city,
            country: card.billingContactInfo.country,
            state: card.billingContactInfo.state,
            zip: card.billingContactInfo.zip
        };
        return await this.api.doPut(this.path + '/UpdateBlueSnapCardExpiry', data);
    }

    async updatePaysafeCard(firstName, lastName, cardId, expiryDate) {
        const data = {
            expiryMonth: parseInt(expiryDate.substring(0, 2)),
            expiryYear: parseInt('20' + expiryDate.substring(expiryDate.length - 2)),
            holderName: `${firstName} ${lastName}`
        };
        return await this.api.doPut(this.path + `/UpdatePaysafeCardExpiry?cardId=${cardId}`, data);
    }

    async updateCheckoutCard(firstName, lastName, card, expiryDate) {
        const data = {
            id: card.id,
            firstName: firstName,
            lastName: lastName,
            expirationMonth: expiryDate.substring(0, 2),
            expirationYear: expiryDate.substring(expiryDate.length - 2),
            address: card.accountHolder.billingAddress.addressLine1,
            city: card.accountHolder.billingAddress.city,
            country: card.accountHolder.billingAddress.country,
            state: card.accountHolder.billingAddress.state,
            zip: card.accountHolder.billingAddress.zip
        };
        return await this.api.doPut(this.path + '/UpdateCheckoutCardExpiry', data);
    }

    async verifyUserReCaptcha(token) {
        const data = { token: token };
        return await this.api.doPost(this.path + '/VerifyUserReCaptcha', data);
    }

    async startVerification(userId, verification) {
        return await this.api.doPost(`${this.path}/${userId}/StartVeriffSession`, { verification });
    }

    async getDocumentDecision(sessionId) {
        return await this.api.doGet(`${this.path}/${sessionId}/GetDocumentDecision`);
    }

    async verificationCategoryCriteriaMet(verificationCategoryName) {
        return await this.api.doGet(`${this.path}/${verificationCategoryName}/GetUserVerificationCategoryCriteriaMet`);
    }

    async getCartData(cartItem, userId, loggingIn, updateCart, orderId = 0) {
        const cartItemObject = Object.assign({}, Array.isArray(cartItem) ? cartItem[0] : cartItem);
        const newCartItem = Object.assign({}, cartItemObject, {
            userCartId: cartItemObject.userCartId ? cartItemObject.userCartId : 0,
            quantity: this.helper.convertNumberWithoutComma(cartItemObject.quantity, 'float'),
            tempQuantity: this.helper.convertNumberWithoutComma(cartItemObject.quantity, 'float'),
            id: cartItemObject.id ?? cartItemObject.productId
        });
        return ({
            userId: userId,
            loggingIn: loggingIn ? loggingIn : false,
            updateCart: updateCart ? updateCart : false,
            product: newCartItem,
            orderId: cartItem.orderId || orderId
        });
    }

    async storeUserCartProducts(cartItem, userId, loggingIn, updateCart, orderId = 0, updateOrder = true) {
        const cartData = await this.getCartData(cartItem, userId, loggingIn, updateCart, orderId);
        return await this.api.doPost(`${this.path}/StoreUserCartProducts`, { ...cartData, updateCart, updateOrder });
    }

    async onCartUpdated() {
        return await this.api.doPost(`${this.path}/OnCartUpdated`);
    }

    async destroyUserCartProducts(id = 0) {
        const cart = await this.getUserCartProducts();
        const orderId = cart.some(x => x.orderId !== null && x?.orderId > 0) ? cart.find(x => x.orderId !== null && x?.orderId > 0).orderId : 0;
        return await this.api.doDelete(`${this.path}/${id}/DestroyUserCartProducts/${orderId}`);
    }

    async updateCartAfterPurchase(id = 0) {
        const cart = await this.getUserCartProducts();
        const order = cart.some(x => x.orderId !== null) ? cart.find(x => x.orderId !== null).orderId : 0;
        const cartId = cart.some(x => x.orderId !== null) ? cart.find(x => x.orderId !== null).userCartId : 0;
        return await this.api.doPut(`${this.path}/${cartId}/updateCartAfterPurchase/${order}`);
    }

    async getUserCartProducts(forceFetch = false) {
        return await this.helper.fetchData(this.api, `${this.path}/GetUserCartProducts`, 'getUserCartProducts', forceFetch);
    }

    async refreshCart(cart, currencyUsed, orderAdClicks) {
        const newCart = cart.map(x => Object.assign({}, x, {
            quantity: this.helper.convertNumberWithoutComma(x.quantity, 'float'),
            tempQuantity: this.helper.convertNumberWithoutComma(x.tempQuantity, 'float')
        }));
        const data = {
            currencyUsed,
            orderAdClicks,
            products: newCart,
            services: newCart.filter(item => item.isService)
        };
        return await this.api.doPost(`${this.path}/RefreshUserCartProducts`, data);
    }

    async getVeriffUserData(id) {
        return await this.api.doGet(`${this.path}/${id}/GetVeriffUserData`);
    }

    async processPaysafe3DS(data) {
        return await this.api.doPost(`${this.path}/ProcessPaysafe3DS`, data);
    }

    async requestIpAuthorization(data) {
        data.websiteShortCode = websiteShortCode();
        return await this.api.doPost('RequestIpAuthorization', data);
    }

    async authorizeIp(data) {
        data.websiteShortCode = websiteShortCode();
        return await this.api.doPost('AuthorizeIp', data);
    }

    async checkEmail(email) {
        return await this.api.doGet(`${this.path}/CheckEmail/${email?.trim()}`);
    }

    async verify2FA(token) {
        return await this.api.doPost(`${this.path}/VerifyTwoFactor`, { token });
    }

    async linkDiscordToProfile(tokenType, accessToken) {
        return await this.api.doPost(`${this.path}/LinkDiscordToProfile`, { tokenType, accessToken });
    }

    async updateDiscordTag() {
        return await this.api.doPost(`${this.path}/UpdateDiscordTag`);
    }

    async getDiscordProfile(tokenType, accessToken) {
        return await this.api.doGet(`${this.path}/GetDiscordProfile/${tokenType}/${accessToken}`);
    }

    async linkSteamToProfile(steamId) {
        return await this.api.doPost(`${this.path}/LinkSteamToProfile`, { steamId });
    }

    async updateSteamName() {
        return await this.api.doPost(`${this.path}/UpdateSteamName`);
    }

    async getPendingBalanceByUserId() {
        return await this.api.doGet(`${this.path}/GetPendingBalanceByUserId`);
    }

    hasWithdrawPassword = async() => await this.get('HasWithdrawPassword');

    async createWithdrawPassword(newWithdrawPassword, currentWithdrawPassword) {
        return await this.api.doPost(`${this.path}/CreateWithdrawPassword`, { newWithdrawPassword, currentWithdrawPassword });
    }

    async verifyWithdrawPassword(withdrawPassword) {
        const response = await this.api.doPost(`${this.path}/VerifyWithdrawPassword`, withdrawPassword);
        const handler = [
            new ReasonType(ErrorReason.TooManyRequests, NotificationType.Info, null, {
                avoidNotification: true
            })
        ];
        return this.helper.requestErrorHandler(response, handler);
    }

    async validateApplePaySession(applePayUrl) {
        return await this.api.doPost(`${this.path}/ApplePaySession`, applePayUrl);
    }

    async getTotalSpentByUser(id) {
        return await this.api.doGet(`${this.path}/TotalSpentByUser/${id}`);
    }

    async hasFailedPasswordAttempts(id) {
        return await this.api.doGet(`${this.path}/HasFailedPasswordAttempts`);
    }

    getVerificationLevel(user) {
        const validations = [user.phoneVerified, user.emailConfirmed || user.idVerified, user.addressVerified];
        let level = 0;
        for (const validation in validations) {
            if (validation) level++;
            else break;
        }
        return level;
    }
}
