/**
 * @typedef {object} SlugResponse
 * @property {string} routeName - The name of the route.
 * @property {number} gameProductCategoryId - The ID of the game product category.
 * @property {boolean} defaultSlug - Indicates if this is the default slug.
 * @property {number} id - The unique identifier for the slug.
 * @property {null | Date} createdDate - The date when the slug was created, or null if not set.
 * @property {Date} updatedDate - The date when the slug was last updated.
 * @property {boolean} isDeleted - Indicates if the slug has been deleted.
 */
export const SlugResponse = null;

export {};
