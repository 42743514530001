
import { AdvancedProductSearchProductResponse, SteamInventoryItem } from './product-types';

/**
 * @typedef {object} CdsProductSearchSelectedEvent
 * @property {AdvancedProductSearchProductResponse | SteamInventoryItem} product - The product selected in the search.
 * @property {boolean} replace - Indicates whether to replace the current selection.
 */
export const CdsProductSearchSelectedEvent = null;

/**
 * @typedef {object} CdsProductCardSelectedEvent
 * @property {number} quantity - The quantity of the product card selected.
 * @property {boolean} newQuantity - Indicates if the quantity is new.
 */
export const CdsProductCardSelectedEvent = null;
