import { produce } from 'immer';
import {
    AdvancedProductSearchResponse,
    AppState,
    isSteamInventoryItem,
    SteamInventoryItem,
    SwapState
} from 'resources/helpers/types';

/**
 * @type {SwapState}
 */
const initialState = {
    selectedProducts: {
        sellProducts: [],
        purchaseProducts: []
    }
};

/**
 * Clears the selected products from the state based on the type.
 * @param {AppState} state - The current application state.
 * @param {'Sell' | 'Purchase' | 'All'} type - The type of products to clear.
 * @returns {AppState} The new application state with the selected products cleared.
 */
export function clearSelectedProducts(state, type) {
    return produce(state, draftState => {
        switch (type) {
            case 'Sell':
                draftState.swapState.selectedProducts.sellProducts = [];
                break;
            case 'Purchase':
                draftState.swapState.selectedProducts.purchaseProducts = [];
                break;
            default:
                draftState.swapState.selectedProducts.sellProducts = [];
                draftState.swapState.selectedProducts.purchaseProducts = [];
                break;
        }
    });
}

/**
 * Updates the selected products in the state.
 * @param {AppState} state - The current application state.
 * @param {'Sell' | 'Buy'} type - The type of products to update.
 * @param {AdvancedProductSearchResponse | SteamInventoryItem} product - The product to add, replace, or remove.
 * @param {boolean} replace - Whether to replace the existing product or not.
 * @returns {AppState} The new application state with the updated selected products.
 */
export function updateSelectedProducts(state, type, product, replace = false) {
    return produce(state, draftState => {
        let productList;

        if (type === 'Buy') {
            productList = draftState.swapState.selectedProducts.purchaseProducts;
        } else {
            productList = draftState.swapState.selectedProducts.sellProducts;
        }

        const index = productList
            .findIndex(x => isSteamInventoryItem(x) ?
                x.productId === product.productId && x.assetId === product.assetId : x.id === product.id
            );

        if (index === -1) {
            productList.push(product);
        } else if (replace) {
            productList.splice(index, 1, product);
        } else {
            productList.splice(index, 1);
        }
    });
}

export default initialState;
