import { inject } from 'aurelia-framework';
import { SessionService } from 'services/session-service';
import { ToastService } from 'services/toast-service';
import { Router } from 'aurelia-router';
import { baseUrl, chicksGroupBaseUrl, clientId } from 'environment';
import { ToastType } from 'resources/helpers/enums';
import { generateCodeChallenge, generateCodeVerifier, generateNonce, parseAuthQueryParams } from '@chicksgroup/helpers';

@inject(
    SessionService,
    ToastService,
    Router
)
export class AuthenticationHandler {
    /**
     * @type {SessionService}
     */
    sessionService;
    /**
     * @type {ToastService}
     */
    toastService;
    /**
     * @type {Router}
     */
    router;

    /**
     * @param {SessionService} sessionService
     * @param {ToastService} toastService
     * @param {Router} router
     */
    constructor(
        sessionService,
        toastService,
        router
    ) {
        this.sessionService = sessionService;
        this.toastService = toastService;
        this.router = router;
    }

    async handleSSO() {
        const query = parseAuthQueryParams();
        let handledToken = false;

        try {
            const storedNonce = window.localStorage.getItem('nonce');

            const token = query.get('token');
            const nonce = query.get('nonce');

            if (!token || (storedNonce && !nonce)) {
                return;
            }

            const storedVerifier = window.localStorage.getItem('codeVerifier');
            handledToken = true;

            if (storedNonce !== nonce) {
                void this.toastService.showToast(ToastType.ERROR, 'Failed to validate sign in request. Please try again.');
                return;
            }

            const response = await this.sessionService.validateAuthorizationCode(token, clientId(), storedVerifier);

            if (!response?.token) {
                return;
            }

            this.sessionService.saveToken(response.token);
            sessionStorage.setItem('update_cart', 'true');
        } catch (e) {
            console.log(e);
        } finally {
            if (handledToken) {
                window.location.href = window.location.href.split('#')[0];
            }

            localStorage.removeItem('nonce');
            localStorage.removeItem('codeVerifier');
        }
    }

    /**
     * @param {string} route
     * @returns {Promise<void>}
     * @private
     * @external Promise
     */
    async _doLocalLogin(route) {
        const email = prompt('email');
        const password = prompt('password');

        this.sessionService.login({ email, password, skipChecks: true, token: '' }).then(loginResult => {
            this.sessionService.validateAuthorizationCode(loginResult.authorizationCode, clientId()).then(codeResponse => {
                if (!codeResponse?.token) {
                    return;
                }

                this.sessionService.saveToken(codeResponse.token);
                location.reload();
            }).catch(err => {
                console.error(err);
                this.handleCheckoutRedirection(route);
            });
        }).catch(err => {
            console.error(err);
            this.handleCheckoutRedirection(route);
        });
    }

    /**
     * @param {string | null} route
     * @param {string | null} referralLink
     * @param {string | null} authenticationRoute
     */
    async handleCheckoutRedirection(route = 'cart', referralLink = null, authenticationRoute = null) {
        if (this.sessionService.isLoggedIn() && route) {
            this.router.navigateToRoute(route);
            return;
        }

        let redirectUrl = window.location.href;

        if (route || route === '') {
            const { protocol, host } = window.location;
            redirectUrl = `${protocol}//${host}/${route}`;
        }

        if (baseUrl().includes('localhost')) {
            const skipChecks = confirm('skip sso?');

            if (skipChecks) {
                await this._doLocalLogin(route);
                return;
            }
        }

        const nonce = generateNonce(30);
        const codeVerifier = generateCodeVerifier();
        const codeChallenge = await generateCodeChallenge(codeVerifier);

        localStorage.setItem('nonce', nonce);
        localStorage.setItem('codeVerifier', codeVerifier);
        let chicksGroupUrl = chicksGroupBaseUrl();

        const params = new URLSearchParams();
        params.append('redirect', redirectUrl);
        params.append('nonce', nonce);
        params.append('codeChallenge', codeChallenge);
        params.append('clientId', clientId());

        if (referralLink) {
            params.append('referralLink', referralLink);
            chicksGroupUrl = `${chicksGroupUrl}/sign-up`;
        } else if (authenticationRoute) {
            chicksGroupUrl = `${chicksGroupUrl}/${authenticationRoute}`;
        }

        const queryString = params.toString();
        window.location.href = `${chicksGroupUrl}?${queryString}`;
    }
}
