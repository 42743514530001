import { Container } from 'aurelia-framework';
import { AppState } from 'resources/helpers/types';
import { PaymentMethodWebsiteService } from 'services/payment-method-website-service';

export const paymentMethodFeatureKey = 'paymentMethod';

export const initialState = {
    paymentMethods: [],
    recentPaymentMethods: [],
    popularPaymentMethods: []
};

export const reducer = {
    /**
     * Loads payment methods into the state.
     * @param {AppState} state - The current state of the application.
     * @returns {Promise<AppState>} The new state with loaded payment methods.
     * @external Promise
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise}
     */
    load: async(state) => {
        const paymentMethodWebsiteService = Container.instance.get(PaymentMethodWebsiteService);
        const paymentMethods = await paymentMethodWebsiteService.getByWebsite();

        return {
            ...state,
            [paymentMethodFeatureKey]: {
                ...state.paymentMethod,
                paymentMethods
            }
        };
    }
};
