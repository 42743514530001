import { FieldType } from '../enums';

/**
 * @typedef {object} GameFilter
 * @property {number} gameId - The unique identifier for the game.
 * @property {number} productCategoryId - The unique identifier for the product category.
 * @property {FilterField} field - The field associated with the filter.
 */
export const GameFilter = null;

/**
 * @typedef {object} FilterField
 * @property {number} id - The unique identifier for the filter field.
 * @property {string} name - The name of the filter field.
 * @property {string | null} [defaultValue] - The default value for the filter field, if any.
 * @property {string | null} [iconPath] - The path to the icon associated with the filter field, if any.
 * @property {FieldType} type - The type of the filter field.
 * @property {FilterValue} listOfValue - The list of values associated with the filter field.
 */
export const FilterField = null;

/**
 * @typedef {object} FilterValue
 * @property {number} id - The unique identifier for the filter value.
 * @property {string} display - The display name of the filter value.
 * @property {string | null} [value] - The value associated with the filter value, if any.
 * @property {string | null} [label] - The label associated with the filter value, if any.
 * @property {ListOfValue[]} children - The list of child values associated with the filter value.
 */
export const FilterValue = null;

/**
 * @typedef {object} ListOfValue
 * @property {number} id - The unique identifier for the list of value.
 * @property {string} display - The display name of the list of value.
 * @property {string} value - The value associated with the list of value.
 * @property {string | null} [icon] - The icon associated with the list of value, if any.
 * @property {number | null} [position] - The position of the list of value, if any.
 */
export const ListOfValue = null;

export {};
