import { inject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { Helper } from 'resources/extensions/helper';
import { websiteShortCode } from 'environment';
import {
    Product,
    StockProduct,
    ActiveFilter,
    GameFilter,
    AdvancedProductSearchRequest,
    AdvancedProductSearchResponse,
    ProductOffer
} from 'resources/helpers/types';
import { ProductCategoryType } from 'resources/helpers/enums';

const STOCK_PRODUCTS_PATH = 'products_path';
const PRODUCT_PATH = 'product_path';

@inject(ApiService, Helper)
export class ProductService {
    path = 'Product';
    productGameCurrencies;

    /**
     * @param {ApiService} api
     * @param {Helper} helper
     */
    constructor(api, helper) {
        this.api = api;
        this.helper = helper;
    }

    getRoute = (route) => `${this.path}${route ? '/' : ''}${route}`;

    get = async(route) => await this.api.doGet(this.getRoute(route));

    getParams = async(route, params) => await this.get(this.helper.toParams(route, params));

    /**
     * @param {number} id
     * @returns {Promise<Product | null>} A promise that resolves to a product or null if not found.
     * @external Promise
     */
    async getProductById(id) {
        return await this.api.doGet(`${this.path}/${id}`, { websiteShortCode: websiteShortCode() });
    }

    async getProductsByIds(ids) {
        return await this.api.doGet(`${this.path}/Ids`, { ids: ids });
    }

    /**
     * Gets the products filtered by the specified params
     * @param {string} filter A category, e.g.: InStockCurrency, Balance, ChicksVIP, etc.
     * @param {string} gameId The selected game ID to filter the products
     * @returns {Promise<StockProduct[]>} A list of products
     * @external Promise
     */
    async getProductsWithFilter(filter, gameId = null) {
        const path = this.getProductPath();
        return await this.api.doGet(this.path, { filter, gameId, path });
    }

    async getProductsByGameId(gameId) {
        return await this.getProductsWithFilter('PerGame', gameId);
    }

    /**
     * Gets the products for the specified filter, e.g.: Items, Accounts, Skins, etc.
     * @param {string} filter The category of the products
     * @param {number} gameId The selected game ID to filter the products
     * @param {number} page The current page of the products
     * @param {string} take Amount of products to get
     * @param {string} search The query to which the products will be filtered
     * @param {string} sort A sorting order for the products
     * @param {number} minPrice The minimum price to filter the products
     * @param {number} maxPrice The maximun price to filter the products
     * @param {string} activeFilters Extra filters to sort out the products
     * @returns {StockProduct[]} A list of products
     */
    async getStockProducts(filter, gameId, page = null, take = null, search = null, sort = null, minPrice = null, maxPrice = null, activeFilters = null) {
        maxPrice = this.helper.validateNumber(maxPrice);
        minPrice = this.helper.validateNumber(minPrice);
        return await this.api.doGet(`${this.path}/StockProducts`, { websiteShortCode: websiteShortCode(), filter, gameId, page, take, search, sort, minPrice, maxPrice, activeFilters: this.handleActiveFilters(activeFilters) });
    }

    /**
     * Returns the filters that contain a value and aren't 'All'
     * @param {ActiveFilter[]} activeFilters A list of filters to sort out the products
     * @returns {string} The active filters received by paremeters stringified
     */
    handleActiveFilters(activeFilters) {
        activeFilters = structuredClone(activeFilters);
        activeFilters = activeFilters?.filter(x => x && (x.value || (x.startValue >= 0 && x.endValue >= 0)) && (!x.display || x.display?.trim()?.toLowerCase() !== 'all') && (!x.value || x.value?.trim()?.toLowerCase() !== 'all'));
        activeFilters?.forEach(x => {
            delete x.name;
            if (!x?.value) return;
            x.value = x.value.replaceAll('&', '%26');
        });
        return activeFilters?.length > 0 ? JSON.stringify(activeFilters) : null;
    }

    async getStockProductsCount(filter, gameId, search = null, minPrice = null, maxPrice = null, activeFilters = null) {
        maxPrice = this.helper.validateNumber(maxPrice);
        minPrice = this.helper.validateNumber(minPrice);
        return await this.getParams('StockProductsCount', { filter, gameId: gameId, search, minPrice, maxPrice, activeFilters: this.handleActiveFilters(activeFilters), websiteShortCode: websiteShortCode() });
    }

    /**
     * @param {ProductCategoryType} category
     * @param {number} gameId
     * @returns {Promise<GameFilter[] | null>} A promise that resolves to a list of game filters or null if not found.
     */
    async getGameFilters(category, gameId) {
        return await this.api.doGet(`ProductCategoryGameFields/game/${gameId}/category/${category}`);
    }

    /**
     * @param {number} categoryId
     * @param {number} gameId
     * @param {boolean} [isDescription]
     * @returns {Promise<GameFilter[] | null>} A promise that resolves to a list of game filters or null if not found.
     */
    async getProductFilters(categoryId, gameId, isDescription = false) {
        return await this.api.doGet(`ProductCategory/${categoryId}/game/${gameId}/isDescription/${isDescription}/fields`);
    }

    async getAllUniqueSkillsForGame(gameShortName, gameModeId) {
        return await this.api.doGet(`${this.path}/AllUniqueSkillsForGame?gameShortName=${gameShortName}&gameModeId=${gameModeId}`);
    }

    async getProductGameForPaymentMethodCurrencies(gameIds) {
        if (this.productGameCurrencies) {
            return this.productGameCurrencies;
        }
        this.productGameCurrencies = await this.api.doPost(`${this.path}/GetProductGameForPaymentMethodCurrencies`, gameIds);
        return this.productGameCurrencies;
    }

    /**
     * @param {AdvancedProductSearchRequest} request
     * @returns {Promise<AdvancedProductSearchResponse>} A promise that resolves to an advanced product search response.
     * @external Promise
     */
    async doAdvancedProductSearch(request) {
        return await this.api.doPost(`${this.path}/search`, request);
    }

    async getFieldsForGameAndCategorySkinDescription(categoryId, gameId, isDescription) {
        return await this.api.doGet('ProductCategory/' + categoryId + '/game/' + gameId + '/isDescription/' + isDescription + '/fields');
    }

    /**
     * Saves the current route for retrieving the stock products in the local storage
     * @param {string} productsPath The current route of the products page
     * @returns {void}
     */
    saveStockProductsPath(productsPath) {
        localStorage.setItem(STOCK_PRODUCTS_PATH, productsPath);
    }

    /**
     * Gets the stock products route from the storage
     * @returns {string} The value of the products route from the local storage
     */
    getStockProductsPath() {
        return localStorage.getItem(STOCK_PRODUCTS_PATH);
    }

    /**
     * Removes the stock products route from the storage
     * @returns {void}
     */
    removeStockProductsPath() {
        localStorage.removeItem(STOCK_PRODUCTS_PATH);
    }

    /**
     * Saves the current route for retrieving the product in the local storage
     * @param {string} productPath The current route of the product page
     * @returns {void}
     */
    saveProductPath(productPath) {
        localStorage.setItem(PRODUCT_PATH, productPath);
    }

    /**
     * Gets the product route from the storage
     * @returns {string} The value of the product route from the local storage
     */
    getProductPath() {
        return localStorage.getItem(PRODUCT_PATH);
    }

    /**
     * Removes the product route from the storage
     * @returns {void}
     */
    removeProductPath() {
        localStorage.removeItem(PRODUCT_PATH);
    }

    /**
     * Sends a request to place a product offer through the API.
     * @async
     * @function doPlaceOffers
     * @param {ProductOffer} request - The data for the product offer to be placed, containing all necessary information such as product details, pricing, and seller information.
     * @returns {Promise<object>} A promise that resolves with the response from the API after attempting to place the offer.
     */
    async doPlaceOffers(request) {
        return await this.api.doPost(`${this.path}/Seller`, request);
    }
}
