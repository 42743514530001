import { DeliveryPeriod } from '../enums';
import { Product } from './product-types';

/**
 * @typedef {object} SellerSearchSellerByIdResponse
 * @property {string} username - The username of the seller
 * @property {string | null} avatarImagePath - The path to the seller's avatar image, or null if not set
 * @property {string} description - A description of the seller
 * @property {number} feedbackScore - The feedback score of the seller
 * @property {number} userId - The unique identifier of the seller
 * @property {number} negativeFeedbackCount - The count of negative feedback received by the seller
 * @property {number} positiveFeedbackCount - The count of positive feedback received by the seller
 * @property {number} orderCount - The total number of orders completed by the seller
 * @property {boolean} verified - Whether the seller is verified
 * @property {number | null} deliveryTimeAverage - The average delivery time of the seller, or null if not applicable
 * @property {DeliveryPeriod | null} deliveryPeriodAverage - The average delivery period of the seller, or null if not applicable
 * @property {Product[] | null} products - The list of products offered by the seller, or null if none
 * @property {number} id - The unique identifier of the response
 * @property {Date} createdDate - The date when the response was created
 * @property {Date} updatedDate - The date when the response was last updated
 * @property {boolean} isDeleted - Whether the response is marked as deleted
 */
export const SellerSearchSellerByIdResponse = null;

export {};
