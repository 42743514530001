import { IPaymentMethodWebsiteResponse, IRecentPaymentMethodByUserResponse } from '@chicksgroup/types';
import { SteamInventoryItem, SteamInventory } from './sell-types';
import { AdvancedProductSearchResponse } from './product-types';
import { GameForNav } from './game-types';

/**
 * @typedef {object} SellState
 * @property {SteamInventoryItem[]} selectedProducts - The products that the user has selected to sell
 */
export const SellState = null;

/**
 * @typedef {object} SwapState
 * @property {SelectedProducts} selectedProducts - The products that the user has selected to swap
 */
export const SwapState = null;

/**
 * @typedef {object} SelectedProducts
 * @property {(AdvancedProductSearchResponse | SteamInventoryItem)[]} sellProducts - The products that the user has selected to sell
 * @property {AdvancedProductSearchResponse[]} purchaseProducts - The products that the user has selected to purchase
 */
export const SelectedProducts = null;

/**
 * @typedef {object} PaymentMethodState
 * @property {IPaymentMethodWebsiteResponse[]} paymentMethods - The available payment methods
 * @property {IRecentPaymentMethodByUserResponse[]} recentPaymentMethods - The recent payment methods used by the user
 * @property {IPaymentMethodWebsiteResponse} popularPaymentMethods - The most popular payment methods
 */
export const PaymentMethodState = null;

/**
 * @typedef {object} AppState
 * @property {SellState} sellState - The state related to selling products
 * @property {SwapState} swapState - The state related to swapping products
 * @property {Map<string, GameForNav>} selectedGames - The games selected by the user for navigation
 * @property {'Swap' | 'Sell'} viewLocation - The current view location, either 'Swap' or 'Sell'
 * @property {SteamInventory | null} steamInventory - The user's Steam inventory, or null if not available
 * @property {PaymentMethodState} paymentMethod - The state related to payment methods
 * @external Map
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Map}
 */
export const AppState = null;

export {};
