/**
 * @typedef {object} PageContentArea
 * @property {string | undefined} boxColor - The color of the box, can be undefined.
 * @property {string} title - The title of the content area.
 * @property {string} text - The text content of the area.
 * @property {string} iconPath - The path to the icon image.
 */
export const PageContentArea = null;

export {};
