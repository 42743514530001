import { Subscription } from './subscription-types';
import { GameForNav } from './game-types';

/**
 * @typedef {object} User
 * @property {number} id - Unique identifier for the user.
 * @property {string} firstName - First name of the user.
 * @property {string} lastName - Last name of the user.
 * @property {string} email - Email address of the user.
 * @property {string} avatarImagePath - Path to the user's avatar image.
 * @property {boolean} idVerified - Indicates if the user's ID is verified.
 * @property {boolean} emailConfirmed - Indicates if the user's email is confirmed.
 * @property {string} phoneNumber - Phone number of the user.
 * @property {number | null} phoneCountryCode - Country code of the user's phone number.
 * @property {boolean} phoneVerified - Indicates if the user's phone number is verified.
 * @property {string} phoneCountryFlag - Country flag associated with the user's phone number.
 * @property {boolean | null} phoneNumberConfirmed - Indicates if the user's phone number is confirmed.
 * @property {number} balance - Account balance of the user.
 * @property {Subscription[]} subscriptions - List of subscriptions associated with the user.
 * @property {boolean} optedInForEmails - Indicates if the user has opted in for emails.
 * @property {boolean} closedSubscriptionBanner - Indicates if the user has closed the subscription banner.
 * @property {boolean} closedChicksXBanner - Indicates if the user has closed the ChicksX banner.
 * @property {Date | null} closedChicksXBannerDate - Date when the user closed the ChicksX banner.
 * @property {boolean} closedTempPasswordBanner - Indicates if the user has closed the temporary password banner.
 * @property {string} liveChatHash - Hash for the user's live chat session.
 * @property {string[]} roles - List of roles assigned to the user.
 * @property {RefreshToken} refreshToken - Refresh token associated with the user.
 * @property {Blacklist[]} userBlacklist - List of blacklists associated with the user.
 * @property {string} steamId - Steam ID of the user.
 * @property {string} steamName - Steam name of the user.
 */
export const User = null;

/**
 * @typedef {object} RefreshToken
 * @property {string} token - The refresh token string.
 * @property {number} id - Unique identifier for the refresh token.
 * @property {number} expiresAt - Expiration time of the refresh token.
 * @property {boolean} resetPassword - Indicates if the token is for resetting the password.
 * @property {boolean} tokenRequired - Indicates if the token is required.
 * @property {boolean} alreadyRegistered - Indicates if the user is already registered.
 * @property {boolean} incorrectToken - Indicates if the token is incorrect.
 * @property {boolean} tokenUsed - Indicates if the token has been used.
 * @property {boolean} mustValidateNewIP - Indicates if the new IP must be validated.
 * @property {string} requestedIPToValidate - IP address requested to be validated.
 * @property {boolean} invalidLoginWithTemporaryPassword - Indicates if there was an invalid login with a temporary password.
 * @property {string} accessToken - Access token associated with the refresh token.
 * @property {Blacklist[]} userBlacklist - List of blacklists associated with the refresh token.
 * @property {boolean} magicLinkRequired - Indicates if a magic link is required.
 * @property {boolean} expiredAccess - Indicates if the access has expired.
 * @property {Date | null} createdDate - Date when the refresh token was created.
 * @property {object} website - Website associated with the refresh token.
 */
export const RefreshToken = null;

/**
 * @typedef {object} Blacklist
 * @property {number} id - Unique identifier for the blacklist entry.
 * @property {string} information - Information about the blacklist entry.
 * @property {string} type - Type of the blacklist entry.
 * @property {string} reason - Reason for the blacklist entry.
 * @property {number | null} level - Level of the blacklist entry.
 * @property {string} userEmail - Email of the user associated with the blacklist entry.
 * @property {import('../enums').BlacklistCategory} category - Category of the blacklist entry.
 * @property {Date | null} createdDate - Date when the blacklist entry was created.
 * @property {number} gameId - Game ID associated with the blacklist entry.
 * @property {GameForNav} game - Game associated with the blacklist entry.
 * @property {string} principalUserEmail - Principal user's email associated with the blacklist entry.
 * @property {boolean} addByVeriffGroup - Indicates if the entry was added by the Veriff group.
 * @property {boolean} veriffGroupActive - Indicates if the Veriff group is active.
 * @property {number} userThatBanId - ID of the user who banned.
 * @property {User} userThatBan - User who banned.
 */
export const Blacklist = null;

/**
 * @typedef {object} LookupOption
 * @property {string} displayName - The display name of the lookup option.
 * @property {string} value - The value of the lookup option.
 * @property {string} icon - The icon associated with the lookup option.
 */
export const LookupOption = null;

export {};
